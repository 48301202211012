/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/alpine-magic-helpers@1.2.2/dist/index.min.js
 * - /npm/alpinejs@2.8.2/dist/alpine.min.js
 * - /npm/lodash@4.17.21/lodash.min.js
 * - /npm/rellax@1.12.1/rellax.min.js
 * - /npm/js-cookie@2.2.1/src/js.cookie.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
